<template>
  <div class="surface-section w-full p-6 md:w-6 md:p-8">
    <div class="mb-5">
      <div class="text-900 text-3xl font-medium mb-3">Se connecter à ma société de portage</div>
    </div>
    <div>
      <form class="p-fluid">
        <p>Scannez le QR code généré depuis l'application web du nom de la société que vous souhaitez utiliser.</p>
        <divider></divider>
        <Button v-if="!getIsBrowserApp" :label="$t('changeSociety')" icon="fa-regular fa-building" class="w-full p-3 mt-2" :disabled="!deviceReady" @click="launchScan"/>
      </form>
    </div>
  </div>
  <div class="hidden w-6 bg-no-repeat bg-cover" :style="{ backgroundImage: 'url(' + require('@/assets/images/signin/signin.jpg') + ')' }"></div>
</template>

<script>
import Alert from "@/utils/Alert";
import IsBrowserAppMixin from "@/mixins/isBrowserAppMixin";
import {mapState} from "vuex";

export default {
  name: "QrCodeScanner",
  mixins: [IsBrowserAppMixin],
  data () {
    return {
      cameraConfig: null,
      deviceReady: false
    }
  },
  computed: {
    ...mapState({
      QR_CODE_SCAN_FLAG: state => state.misc.QR_CODE_SCAN_FLAG,
      QR_CODE_FORCE_CAMERA_FLAG: state => state.misc.QR_CODE_FORCE_CAMERA_FLAG,
    })
  },
  created () {
    document.addEventListener('deviceready', () => {
      this.deviceReady = true
      window.QRScanner.prepare((err, status) => {
        if (err) {
          this.resetForceCamera()
          this.error()
        }
        if (status.authorized) {
          if (this.QR_CODE_FORCE_CAMERA_FLAG) {
            this.launchScan()
          }
        } else if (status.denied) {
          this.resetForceCamera()
        } else {
          this.resetForceCamera()
        }
      })
    }, false);
  },
  unmounted() {
    document.removeEventListener('deviceready', () => {})
  },
  methods: {
    launchScan () {
      window.QRScanner.scan(this.scanCallback)
      // Make the webview transparent so the video preview is visible behind it.
      window.QRScanner.show()
      this.$store.dispatch('misc/updateQrCodeScanFlag', true)
    },
    scanCallback(err, text) {
      if(err){
        console.log('err', err)
        this.closeScanner()
      } else {
        console.log('text', text)

        this.closeScanner()
        // window.open(encodeURI(text), '_system', '')
        window.cordova.InAppBrowser.open(text, '_system', 'location=yes');
      }
    },
    resetForceCamera () {
      if (this.QR_CODE_FORCE_CAMERA_FLAG) {
        this.$store.dispatch('misc/updateQrCodeForceCameraFlag', false)
      }
    },
    closeScanner () {
      window.QRScanner.cancelScan()
      window.QRScanner.hide()
      window.QRScanner.destroy()
      this.$store.dispatch('misc/updateQrCodeScanFlag', false)
      this.resetForceCamera()
    },
    error(){
      Alert.errorMessage(this, 'cameraPhotoError')
    }
  }
}
</script>

<style scoped>

</style>
